import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { api_getCustomMessageByCode } from '~/backend/api_calls';

const TopMessage = ({ topMessage, setTopMessage }) => {
	const { t, i18n } = useTranslation();

	// const homeHeaderHeight = window.document.querySelector('.home-header').clientHeight + 5;
	// console.log(homeHeaderHeight)
	// window.document.getElementById('menu-modal').style.top = `${homeHeaderHeight}px`;

	const showTopMessage = msg => {
		setTopMessage(msg);
		if (msg) {
			window.document.body.classList.add('top-message');
		}
	};

	useEffect(() => {
		api_getCustomMessageByCode('top-message')
			.then(json => {
				let closeDate = localStorage.getItem('topmessage_close_date');

				if (closeDate) {
					const validDate = new Date(closeDate);
					validDate.setDate(validDate.getDate() + 1);

					if (validDate < new Date()) {
						showTopMessage(json);
					}
				} else {
					showTopMessage(json);
				}
			})
			.catch(error => console.error(error));
	}, []);

	const handleCloseTopMessage = () => {
		document.body.classList.remove('top-message');
		setTopMessage(null);
		localStorage.setItem('topmessage_close_date', new Date());
	};

	return (
		<>
			{topMessage && (
				<div className="topbar">
					<span
						id="top-message"
						dangerouslySetInnerHTML={{
							__html: topMessage?.bd
						}}
					/>
					<button type="button" onClick={() => handleCloseTopMessage()}>
						<svg xmlns="http://www.w3.org/2000/svg" width="13.435" height="13.435" viewBox="0 0 13.435 13.435">
							<g transform="translate(-1181.793 -188.793)">
								<line x2="12.021" y2="12.021" transform="translate(1182.5 189.5)" fill="none" stroke="#fff" strokeWidth="2" />
								<line x1="12.021" y2="12.021" transform="translate(1182.5 189.5)" fill="none" stroke="#fff" strokeWidth="2" />
							</g>
						</svg>
					</button>
				</div>
			)}
		</>
	);
};

TopMessage.propTypes = {
	topMessage: PropTypes.object,
	setTopMessage: PropTypes.any
};

export default TopMessage;
