import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { api_getProductDimensions } from '~/backend/api_calls';
import ShopCart from '~/backend/shop_cart';
import { calculateProductPrice } from '~/common/helper';

import { dropDownSelectStyles } from '~/common/selects.js';

import Select from 'react-select';

import { sendAddToCartGTAG4Event, sendRemoveFromCartGTAG4Event } from '~/common/gtag4.js';
import { sendAddToCartGTMEvent, sendRemoveFromCartGTMEvent } from '~/common/gtm.js';
import deleteProd from '~/images/close-filters.svg';

const CartItem = ({ cartItem, setCartItems }) => {
	const { t, i18n } = useTranslation();
	const [color, setColor] = useState(null);
	const [dimension, setDimension] = useState(null);
	const [image, setImage] = useState(null);
	const defaultValue = { value: cartItem.quantity, label: `${cartItem.quantity}` };
	const [maxQuantity, setMaxQuantity] = useState();
	const [errorMessage, setErrorMessage] = useState('');

	useEffect(() => {
		if (cartItem.dimension === undefined) {
			if (cartItem.product.qty === undefined) {
				setMaxQuantity(0);
				return;
			}
			setMaxQuantity(cartItem.product.qty);
			return;
		}

		api_getProductDimensions(cartItem.product.id).then(json => {
			for (let i = 0; i < json.items.length; i++) {
				let item = json.items[i];
				if (item.id === cartItem.dimension) {
					if (item.chex) {
						setColor(item);
					} else {
						setDimension(item);

						const maxquantity = item.qty !== undefined ? item.qty : cartItem.product.qty;
						setMaxQuantity(maxquantity);
					}
					setImage(SetImageToCart(json.items, item));
					//setImage(item.img1);
				}
			}
		});
	}, []);

	useEffect(() => {
		validateProductAvailability(maxQuantity);
	}, [cartItem, maxQuantity]);

	const validateProductAvailability = maxQuantity => {
		if (maxQuantity <= 0) {
			setErrorMessage(t('cart_item_error_sold_out'));
		} else if (cartItem.quantity > maxQuantity) {
			setErrorMessage(t('cart_item_error_max_quantity') + maxQuantity);
		} else {
			setErrorMessage('');
		}
	};

	let optionsArray = [];
	for (let i = 1; i <= maxQuantity; i++) {
		if (i <= 20) {
			optionsArray.push({ value: i, label: `${i}` });
		}
	}

	if (optionsArray.length === 0) {
		optionsArray.push({ value: 0, label: `0` });
	}

	const SetImageToCart = items => {
		try {
			var groupedByColorItems = [];
			var selectedItem = items.filter(i => i.id === cartItem.dimension && i.chex);
			var selectedChex = selectedItem[0].chex;
			var selectedItemsByChex = items.filter(i => i.chex === selectedChex);
			var imagePath = '';

			groupedByColorItems.push(...selectedItem, ...selectedItemsByChex);

			for (let i = 0; i < groupedByColorItems.length; i++) {
				if (groupedByColorItems[i].img1) {
					imagePath = groupedByColorItems[i].img1;
					break;
				} else if (groupedByColorItems[i].img2) {
					imagePath = groupedByColorItems[i].img2;
					break;
				} else if (groupedByColorItems[i].img3) {
					imagePath = groupedByColorItems[i].img3;
					break;
				} else if (groupedByColorItems[i].img4) {
					imagePath = groupedByColorItems[i].img4;
					break;
				}
			}

			if (imagePath === '') {
				if (cartItem.product.img1) {
					imagePath = cartItem.product.img1;
				} else if (cartItem.product.img2) {
					imagePath = cartItem.product.img2;
				} else if (cartItem.product.img3) {
					imagePath = cartItem.product.img3;
				} else if (cartItem.product.img4) {
					imagePath = cartItem.product.img4;
				}
			}
		} catch (error) {}

		return imagePath;
	};

	const options = optionsArray;

	const removeCartItem = () => {
		sendRemoveFromCartGTAG4Event(cartItem.product, color?.id, cartItem.quantity);
		sendRemoveFromCartGTMEvent(cartItem.product, color?.id, cartItem.quantity);
		setCartItems(ShopCart.remove(cartItem));
	};

	const changeCartItemQuantity = e => {
		// Check if changing amount is higher than current qty.
		// If yes trigger add_to_cart by difference in the qty.
		let difference = 0;

		if (e.value > cartItem.quantity) {
			// Adding more qty.
			difference = e.value - cartItem.quantity;
			sendAddToCartGTMEvent(cartItem.product, color.id, difference);
			sendAddToCartGTAG4Event(cartItem.product, color.id, difference);
		} else if (e.value < cartItem.quantity && e.value > 0) {
			// Removing qty.
			difference = cartItem.quantity - e.value;
			sendRemoveFromCartGTMEvent(cartItem.product, color.id, difference);
			sendRemoveFromCartGTAG4Event(cartItem.product, color.id, difference);
		}

		if (e.value <= 0) {
			removeCartItem();
			return;
		}

		cartItem.quantity = e.value;
		setCartItems(ShopCart.changeQuantity(cartItem));
	};

	return (
		<div className="cart-item grid-col">
			<div className="picture">
				<Link to={cartItem.product.eurl ?? ''}>
					<img src={image ? image : cartItem.product.img1} alt={cartItem.product.nm} />
				</Link>
			</div>
			<div className="cart-item-info flex-col">
				<Link to={cartItem.product.eurl ?? ''}>
					<h4>{cartItem.product.nm}</h4>
				</Link>
				{color ? (
					<div className="color">
						{t('cart_item_color')}: <span>{color.cvtxt}</span>
						<div
							className="color-hex"
							style={{
								backgroundColor: color.chex,
								height: 11,
								width: 11
							}}></div>
					</div>
				) : null}
				{dimension ? (
					<div className="dimension">
						{t('cart_item_dimension')}: <span>{dimension.svtxt}</span>
					</div>
				) : null}
				<div className="quantity">
					<Select
						defaultValue={defaultValue}
						options={options}
						onChange={e => {
							changeCartItemQuantity(e);
						}}
						styles={dropDownSelectStyles}
						classNamePrefix="select"
					/>
					{/*https://react-select.com/components#adjusting-the-styling*/}
				</div>
			</div>
			<div className="edit flex-col">
				<button type="button" className="remove-cart-item" onClick={() => removeCartItem()}>
					<img align="right" src={deleteProd} alt="delete-product" />
				</button>
				<div className="bottom-right">
					<div className="price">{calculateProductPrice(cartItem.product, cartItem.dimension)}</div>
					{errorMessage.length > 0 ? <div className="cart-item-error">{errorMessage}</div> : null}
				</div>
			</div>
		</div>
	);
};
CartItem.propTypes = {
	id: PropTypes.number,
	cartItem: PropTypes.object,
	setCartItems: PropTypes.any
};
export default CartItem;
