import { Outlet, useLocation } from 'react-router';
import FixedButtons from '~/components/common/FixedButtons';
import MenuModal from '~/components/common/MenuModal';
import HeaderCheckout from '~/components/layout/header/HeaderCheckout';
import Footer from './footer/Footer';
import Header from './header/Header';

const LayoutClassic = ({ children }) => {
	const { pathname } = useLocation();

	const defineLayoutToRender = () => {
		if (pathname.includes('/checkout-step-1')) {
			return (
				<>
					<HeaderCheckout step={1} />
					<Outlet />
				</>
			);
		} else if (pathname.includes('/checkout-step-2')) {
			return (
				<>
					<HeaderCheckout step={2} />
					<Outlet />
					<Footer />
				</>
			);
		} else if (pathname.includes('/checkout-step-3')) {
			return (
				<>
					<HeaderCheckout step={3} />
					<Outlet />
					<Footer />
				</>
			);
		} else if (pathname.startsWith('/checkout-success') || pathname.includes('/checkout-failure')) {
			return (
				<>
					<HeaderCheckout step={3} />
					<Outlet />
				</>
			);
		} else {
			return (
				<>
					<Header />
					<Outlet />
					<Footer />
					<FixedButtons />
					<MenuModal />
				</>
			);
		}
	};

	return defineLayoutToRender();
};

export default LayoutClassic;
