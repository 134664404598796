import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { api_getAddressesByCustomerId, api_getProfile } from '~/backend/api_calls';
import { dropDownSelectStylesStatePC, focusSelect, unFocusSelect } from '~/common/selects';
import { selectedNewAddressAction, setSelectedAddressCodeAction, setSelectedAddressIdAction } from '~/store/actions';

const Addresses = ({ metaData, setMetaData }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const selectedAddressId = useSelector(state => state.checkoutReducer.selectedAddressId);
	const selectedNewAddress = useSelector(state => state.checkoutReducer.selectedNewAddress);

	const [addresses, setAddresses] = useState([]);

	useEffect(() => {
		api_getProfile().then(json => {
			api_getAddressesByCustomerId(json.id).then(json => {
				// Add to each address the label & value properties to be compatible with React Select
				const addressesJson = json.map(address => {
					return {
						...address,
						label: `${address.tl} | ${address.adl} ${address.ct} ${address.pc}`,
						value: address.id
					};
				});

				// Also add an option for new address
				setAddresses([{ id: 0, label: t('add_new_address'), value: 0 }, ...addressesJson]);

				if (json.length > 0 && !selectedNewAddress) {
					const previouslySelectedAddress = json.find(address => address.id === selectedAddressId);
					handleSelectAddress(previouslySelectedAddress ?? json[0]);
				}
			});
		});
	}, []);

	const handleSelectAddress = address => {
		if (!address.id) {
			handleSelectAddNewAddress();
			return;
		}

		dispatch(setSelectedAddressIdAction(address.id));
		dispatch(setSelectedAddressCodeAction(address.cd));
		dispatch(selectedNewAddressAction(false));

		setMetaData({
			...metaData,
			billingCountryId: address.cnid,
			billingName: address.fn,
			billingLastname: address.ln,
			billingTelephone: address.mob,
			billingRegion: address.ct,
			billingRegionId: address.crid,
			billingCity: address.ct,
			billingAddress: address.adl,
			billingPostalCode: address.pc
		});
	};

	const handleSelectAddNewAddress = () => {
		dispatch(setSelectedAddressIdAction(0));
		dispatch(setSelectedAddressCodeAction(''));
		dispatch(selectedNewAddressAction(true));

		setMetaData({
			...metaData,
			billingCountryId: '',
			billingName: '',
			billingLastname: '',
			billingTelephone: '',
			billingRegion: '',
			billingCity: '',
			billingAddress: '',
			billingPostalCode: ''
		});
	};

	return (
		<div className="addresses-container">
			<Select
				id="addressSelect"
				placeholder={t('choose_address')}
				options={addresses}
				onChange={e => handleSelectAddress(e)}
				onMenuClose={() => unFocusSelect('addressSelect')}
				onMenuOpen={() => focusSelect('addressSelect')}
				styles={dropDownSelectStylesStatePC}
				value={addresses.find(x => x.id === selectedAddressId) ?? 'SELECT'}
			/>
		</div>
	);
};

Addresses.propTypes = {
	metaData: PropTypes.object,
	setMetaData: PropTypes.func
};

export default Addresses;
