import { Link } from 'react-router-dom';
import UserSession from '~/backend/user_session';

const ImpersonationHeader = () => {
	// console.log(UserSession.isImpersonation());

	if (!UserSession.isAuthenticated() || !UserSession.isImpersonation()) {
		return null;
	}

	return (
		<>
			<div id="impersonation-header">
				<span>Είστε συνδεδεμένος σαν τον χρήστη {UserSession.getEmail()}</span>
				<Link to="/logout">Λήξη συνεδρίας </Link>
			</div>
		</>
	);
};

export default ImpersonationHeader;
