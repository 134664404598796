import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { api_getPaymentTypes } from '~/backend/api_calls';
import { paymentSelectAction } from '~/store/actions';
import { supportPaymentOptionsIds } from '../utils/giftcard';

const PaymentOptions = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const payment = useSelector(state => state.checkoutReducer.payment);

	const [paymentOptions, setPaymentOptions] = useState([]);

	useEffect(() => {
		const abortController = new AbortController();
		const signal = abortController.signal;

		api_getPaymentTypes(signal)
			.then(json => {
				const paymentOptions = json.items.filter(option => supportPaymentOptionsIds.includes(option.id));
				setPaymentOptions(paymentOptions);
			})
			.catch(err => console.log(err));

		return () => {
			abortController.abort();
		};
	}, []);

	return (
		<div className="payment-options">
			<h3 className="payment-options-title"> {t('checkout_step3_payment_title')} </h3>
			<div className="payment-options-container">
				{paymentOptions.map((option, index) => (
					<div key={index} className="payment-option" onClick={() => dispatch(paymentSelectAction(option))}>
						<input type="radio" value={0} name="payment_option_radio" onChange={() => console.log()} checked={option.id === payment?.id} />
						<div className="title">
							<img src={option.img1} alt="Payment Option" />
							<span>{option.nm}</span>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default PaymentOptions;
