import { all, fork } from 'redux-saga/effects';

// Add more saga functions here

// Auth
import authSaga from '~/store/auth/saga';

// Checkout
import checkoutSaga from '~/core/checkout/store/saga';

// Register
import registerSaga from '~/store/register/saga';

// cart
import cartSaga from '~/store/cart/saga';

export default function* rootSaga() {
	yield all([fork(authSaga), fork(checkoutSaga), fork(registerSaga), fork(cartSaga)]);
}
