import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import UserSession from '~/backend/user_session';

import prevStep from '~/images/arrow-right.svg';

import GiftcardStep1 from './GiftcardStep1';
import GiftcardStep2 from './GiftcardStep2';
import GiftcardStep3 from './GiftcardStep3';
import GiftcardStep4 from './GiftcardStep4';
import GiftcardStep5 from './GiftcardStep5';

const Giftcard = () => {
	const { t } = useTranslation();
	const [currentStep, setCurrentStep] = useState(1);
	const [metaData, setMetaData] = useState({
		giftcard: {},
		name: '',
		friendName: '',
		qty: 1,
		message: '',
		recipientEmail: '',
		confirmRecipientEmail: '',
		sendDate: '',
		billingCountryId: 1,
		billingEmail: UserSession.isAuthenticated() ? UserSession.getEmail() : '',
		billingName: '',
		billingLastname: '',
		billingTelephone: '',
		billingRegion: '',
		billingRegionId: '',
		billingCity: '',
		billingAddress: '',
		billingPostalCode: ''
	});

	const renderCurrentStep = () => {
		switch (currentStep) {
			case 1:
				return <GiftcardStep1 currentStep={currentStep} setCurrentStep={setCurrentStep} metaData={metaData} setMetaData={setMetaData} />;
			case 2:
				return <GiftcardStep2 currentStep={currentStep} setCurrentStep={setCurrentStep} metaData={metaData} setMetaData={setMetaData} />;
			case 3:
				return <GiftcardStep3 currentStep={currentStep} setCurrentStep={setCurrentStep} metaData={metaData} setMetaData={setMetaData} />;
			case 4:
				return <GiftcardStep4 currentStep={currentStep} setCurrentStep={setCurrentStep} metaData={metaData} setMetaData={setMetaData} />;
			case 5:
				return <GiftcardStep5 currentStep={currentStep} setCurrentStep={setCurrentStep} metaData={metaData} setMetaData={setMetaData} />;
			default:
				break;
		}
	};

	return (
		<div id="giftcard-page">
			<div className="giftcard-header-banner">
				<div className="content-wrapper giftcard-content-wrapper">
					<div className="giftcard-container">
						{/* <div className="details">
							<h2>Gift card</h2>
						</div>
						<div className="giftcard-img">
							<img src={giftcard} alt="Sportistas Giftcard" />
						</div> */}
					</div>
				</div>
			</div>
			<div className="giftcard-steps content-wrapper giftcard-content-wrapper">
				{currentStep > 1 && (
					<div className="prev-step" onClick={() => setCurrentStep(currentStep - 1)}>
						<img src={prevStep} alt="previous step" />
						<span>{t('back')} </span>
					</div>
				)}
				{renderCurrentStep()}
			</div>
		</div>
	);
};

export default Giftcard;
