import CookiesConsent from '~/backend/cookies_consent';

const initialState = {
	necessary: true,
	preferences: CookiesConsent.preferencesConsent(),
	marketing: CookiesConsent.marketingConsent(),
	performance: CookiesConsent.performanceConsent()
};

const cookiesReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'consent-cookies':
			state = { ...action.args };
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};

export default cookiesReducer;
