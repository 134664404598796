// User object defined as a closure wrapper
var UserSession = (function () {
	var initWithJson = function (data) {
		try {
			localStorage.setItem('usr_fe_uid', data.id);
			localStorage.setItem('usr_fe_eml', data.eml);
			localStorage.setItem('usr_fe_nm', data.nm);
			localStorage.setItem('usr_fe_lm', data.lm);
			localStorage.setItem('usr_fe_gd', data.gd);

			localStorage.setItem('usr_fe_at', data.at);
			localStorage.setItem('usr_fe_rt', data.rt);
			localStorage.setItem('usr_fe_ad', data.ad);
			localStorage.setItem('usr_fe_rd', data.rd);

			localStorage.setItem('usr_fe_ld', new Date());

			// if there is a customer Impersonation store it here
			localStorage.setItem('usr_impersonation', data.is_impersonation);
		} catch (e) {
			console.log(e);
		}
	};

	var parseRefreshToken = function (data) {
		try {
			localStorage.setItem('usr_fe_at', data.at);
			localStorage.setItem('usr_fe_rt', data.rt);
			localStorage.setItem('usr_fe_ad', data.ad);
			localStorage.setItem('usr_fe_rd', data.rd);

			localStorage.setItem('usr_fe_ld', new Date());
		} catch (e) {
			console.log(e);
		}
	};

	var clearData = function () {
		try {
			localStorage.removeItem('usr_fe_uid');
			localStorage.removeItem('usr_fe_eml');
			localStorage.removeItem('usr_fe_nm');
			localStorage.removeItem('usr_fe_lm');
			localStorage.removeItem('usr_fe_gd');

			localStorage.removeItem('usr_fe_at');
			localStorage.removeItem('usr_fe_rt');
			localStorage.removeItem('usr_fe_ad');
			localStorage.removeItem('usr_fe_rd');

			localStorage.removeItem('usr_fe_ld');

			localStorage.removeItem('usr_impersonation');
		} catch (e) {
			console.log(e);
		}
	};

	var isAuthenticated = function () {
		try {
			if (localStorage.getItem('usr_fe_ld') == null) {
				return false;
			}

			var refreshExpiry = new Date(localStorage.getItem('usr_fe_ld'));
			let expiryMinutes = parseInt(localStorage.getItem('usr_fe_rd'));
			refreshExpiry.setTime(refreshExpiry.getTime() + expiryMinutes * 60 * 1000);
			let curDate = new Date();

			if (curDate.getTime() > refreshExpiry.getTime()) {
				return false;
			}

			return getId() !== null;
		} catch (e) {
			console.err(e);
			return false;
		}
	};

	var isImpersonation = function () {
		try {
			if (localStorage.getItem('usr_impersonation') === 'true') {
				return true;
			}

			return false;
		} catch (e) {
			console.err(e);
			return false;
		}
	};

	var hasValidOrderState = function () {
		if (isAuthenticated()) return true;

		if (getEmail() != null && getEmail().length > 0) return true;

		return false;
	};

	var getId = function () {
		return localStorage.getItem('usr_fe_uid');
	};

	var getEmail = function () {
		return localStorage.getItem('usr_fe_eml');
	};

	var setEmail = function (email) {
		localStorage.setItem('usr_fe_eml', email);
	};

	var getName = function () {
		return localStorage.getItem('usr_fe_nm');
	};

	var getSurname = function () {
		return localStorage.getItem('usr_fe_lm');
	};

	var getFullName = function () {
		return getName() + ' ' + getSurname();
	};

	var getGender = function () {
		return localStorage.getItem('usr_fe_gd');
	};

	var setGender = function (gender) {
		localStorage.setItem('usr_fe_gd', gender);
	};

	var getAccessToken = function () {
		return localStorage.getItem('usr_fe_at');
	};

	var getRefreshToken = function () {
		return localStorage.getItem('usr_fe_rt');
	};

	var getAccessTokenDuration = function () {
		return localStorage.getItem('usr_fe_ad');
	};

	var getRefreshTokenDuration = function () {
		return localStorage.getItem('usr_fe_rd');
	};

	var getLoginDate = function () {
		return localStorage.getItem('usr_fe_ld');
	};

	var getActiveLanguage = function () {
		// Note: Language is controlled in LanguageDropdown component
		return localStorage.getItem('I18N_LANGUAGE');
	};

	return {
		initWithJson,
		parseRefreshToken,
		clearData,
		isAuthenticated,
		hasValidOrderState,
		getId,
		getEmail,
		setEmail,
		getGender,
		setGender,
		getName,
		getSurname,
		getFullName,
		getAccessToken,
		getAccessTokenDuration,
		getRefreshToken,
		getRefreshTokenDuration,
		getLoginDate,
		getActiveLanguage,
		isImpersonation
	};
})();

export default UserSession;
