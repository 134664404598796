/*import * as Simpler from "@simplerso/web-sdk";*/
import UserSession from '~/backend/user_session';

export const simplerProduct = productId => {
	if (!process.env.REACT_APP_SIMPLER_API_KEY) return;

	const lang = UserSession.getActiveLanguage();

	document.getElementById('simpler-product-page').innerHTML = '';

	window.Simpler.checkout({
		appId: `${process.env.REACT_APP_SIMPLER_API_KEY}`,
		currency: 'EUR',
		locale: lang,
		separator: 'top',
		target: document.getElementById('simpler-product-page'),
		items: [
			{
				id: productId.toString(),
				quantity: 1
			}
		],
		onSuccess: orderId => {
			window.location.href = window.location.origin + `/checkout-success/${orderId}`;
		}
	});
};

export const simplerCartWidget = cartItems => {
	if (!process.env.REACT_APP_SIMPLER_API_KEY) return;

	const products = [];
	const lang = UserSession.getActiveLanguage();

	try {
		cartItems.map(item => {
			let product;

			if (item.dimension) {
				let color = item.product.dimensions.find(x => x.id === item.dimension && x.chex).cvtxt;
				let size = item.product.dimensions.find(x => x.id === item.dimension && !x.chex).svtxt;

				product = {
					id: item.pid.toString(),
					quantity: item.quantity,
					attributes: [
						{
							key: 'attr_size',
							value: 'val_' + size
						},
						{
							key: 'attr_color',
							value: 'val_' + color
						}
					]
				};
			} else {
				product = {
					id: item.pid.toString(),
					quantity: item.quantity
				};
			}

			products.push(product);
		});
	} catch (ex) {
		console.error(ex);
		return;
	}

	window.Simpler.checkout({
		appId: `${process.env.REACT_APP_SIMPLER_API_KEY}`,
		currency: 'EUR',
		locale: lang,
		separator: 'top',
		target: document.getElementById('simpler-minicart'),
		items: products,
		onSuccess: orderId => {
			window.location.href = window.location.origin + `/checkout-success/${orderId}`;
		}
	});
};

export const simplerCart = cartItems => {
	if (!process.env.REACT_APP_SIMPLER_API_KEY) return;

	const products = [];
	const lang = UserSession.getActiveLanguage();

	try {
		cartItems.map(item => {
			let product;

			if (item.dimension) {
				let color = item.product.dimensions.find(x => x.id === item.dimension && x.chex).cvtxt;
				let size = item.product.dimensions.find(x => x.id === item.dimension && !x.chex).svtxt;

				product = {
					id: item.pid.toString(),
					quantity: item.quantity,
					attributes: [
						{
							key: 'attr_size',
							value: 'val_' + size
						},
						{
							key: 'attr_color',
							value: 'val_' + color
						}
					]
				};
			} else {
				product = {
					id: item.pid.toString(),
					quantity: item.quantity
				};
			}

			products.push(product);
		});
	} catch (ex) {
		console.error(ex);
		return;
	}

	window.Simpler.checkout({
		appId: `${process.env.REACT_APP_SIMPLER_API_KEY}`,
		currency: 'EUR',
		locale: lang,
		separator: 'top',
		target: document.getElementById('simpler-cart'),
		items: products,
		onSuccess: orderId => {
			window.location.href = window.location.origin + `/checkout-success/${orderId}`;
		}
	});
};
