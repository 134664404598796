export const unFocusSelect = elementId => {
	document.getElementById(elementId).classList.add('hide');
};

export const focusSelect = elementId => {
	document.getElementById(elementId).classList.remove('hide');
};

export const selectStyles = {
	control: (base, state) => ({
		...base,
		'background': '#fff',
		// Overwrittes the different states of border
		'border': 'none',
		'minHeight': 20,
		// Removes weird border around container
		'boxShadow': state.isFocused ? null : null,
		'&:hover': {
			// Overwrittes the different states of border
			borderColor: state.isFocused ? '#000000' : null
		}
	}),
	container: base => ({
		...base,
		display: 'flex'
	}),
	option: (provided, state) => ({
		...provided,
		'backgroundColor': state.isSelected ? '#ABABAB' : '',
		'color': state.isSelected ? '#fff' : '#000',
		':hover': {
			backgroundColor: '#ABABAB',
			color: '#fff'
		}
	})
};

export const dropDownSelectStylesStatePC = {
	control: (base, state) => ({
		...base,
		// Removes weird border around container
		'boxShadow': state.isFocused ? null : null,
		'width': '100%',
		'background': '#f2f2f2',
		'minHeight': 50,
		'borderColor': '#fff',
		'borderRadius': '5px',
		'&:hover': {
			// Overwrittes the different states of border
			borderColor: state.isFocused ? '#0154FF' : null
		}
	}),
	menu: (base, state) => ({
		...base,
		zIndex: 99
	}),
	container: base => ({
		...base,
		height: 50,
		display: 'flex'
	}),
	clearIndicator: base => ({
		...base,
		display: 'none'
	}),
	placeholder: (base, state) => ({
		...base,
		paddingRight: 0,
		color: state.isSelected || state.isFocused ? '#6E7486' : '#6E7486'
	}),
	input: base => ({
		...base,
		'height': '100%',
		'margin': 0,
		'padding': 0,
		'& input': { height: '100%' }
	}),
	indicatorSeparator: () => {},
	dropdownIndicator: base => ({
		...base,
		'height': 45,
		'paddingRight': 17,
		'& svg': { width: 18, height: 23 },
		'& svg path': { fill: '#6E7486', strokeWidth: 1 }
	}),
	option: (provided, state) => ({
		...provided,
		'backgroundColor': state.isSelected ? '#ABABAB' : '',
		'color': state.isSelected ? '#fff' : '#000',
		':hover': {
			backgroundColor: '#ABABAB',
			color: '#fff'
		}
	})
};

export const dropDownSelectStylesGeneral = {
	control: (base, state) => ({
		...base,
		// Removes weird border around container
		'boxShadow': state.isFocused ? null : null,
		'width': '100%',
		'background': '#f4f4f4',
		'minHeight': 46,
		'borderColor': '#f4f4f4',
		'&:hover': {
			// Overwrittes the different states of border
			borderColor: state.isFocused ? '#000000' : null
		}
	}),
	menu: (base, state) => ({
		...base,
		zIndex: 99
	}),
	container: base => ({
		...base,
		height: 48,
		display: 'flex'
	}),
	clearIndicator: base => ({
		...base,
		display: 'none'
	}),
	placeholder: (base, state) => ({
		...base,
		paddingRight: 0,
		color: state.isSelected || state.isFocused ? '#000' : '#7B7B7B'
	}),
	input: base => ({
		...base,
		'height': '100%',
		'margin': 0,
		'padding': 0,
		'& input': { height: '100%' }
	}),
	indicatorSeparator: () => {},
	dropdownIndicator: base => ({
		...base,
		'height': 20,
		'padding': 0,
		'& svg': { width: 18, height: 18 },
		'& svg path': { fill: '#000000', strokeWidth: 1 }
	}),
	option: (provided, state) => ({
		...provided,
		'backgroundColor': state.isSelected ? '#ABABAB' : '',
		'color': state.isSelected ? '#fff' : '#000',
		':hover': {
			backgroundColor: '#ABABAB',
			color: '#fff'
		}
	})
};

export const dropDownSelectStyles = {
	control: (base, state) => ({
		...base,
		'background': '#fff',
		// Overwrittes the different states of border
		'minWidth': 54,
		'minHeight': 32,
		'borderColor': '#000',
		// Removes weird border around container
		'boxShadow': state.isFocused ? null : null,
		'&:hover': {
			// Overwrittes the different states of border
			borderColor: state.isFocused ? '#000000' : null
		}
	}),
	menu: (base, state) => ({
		...base,
		zIndex: 99
	}),
	container: base => ({
		...base,
		height: 34,
		width: 54,
		display: 'flex'
	}),
	valueContainer: base => ({
		...base,
		padding: '2px'
	}),
	clearIndicator: base => ({
		...base,
		display: 'none'
	}),
	placeholder: (base, state) => ({
		...base,
		paddingRight: 0,
		color: state.isSelected || state.isFocused ? '#000' : '#7B7B7B'
	}),
	input: base => ({
		...base,
		'height': '100%',
		'margin': 0,
		'padding': 0,
		'& input': { height: '100%' }
	}),
	indicatorSeparator: () => {},
	dropdownIndicator: base => ({
		...base,
		'height': 20,
		'padding': 0,
		'& svg': { width: 18, height: 18 },
		'& svg path': { fill: '#000000', strokeWidth: 1 }
	}),
	option: (provided, state) => ({
		...provided,
		'backgroundColor': state.isSelected ? '#ABABAB' : '',
		'color': state.isSelected ? '#fff' : '#000',
		':hover': {
			backgroundColor: '#ABABAB',
			color: '#fff'
		}
	})
};

export const multiSelectStyles = {
	control: (base, state) => ({
		...base,
		'background': '#fff',
		// Overwrittes the different states of border
		'border': 'none',
		'minHeight': 28,
		// Removes weird border around container
		'boxShadow': state.isFocused ? null : null,
		'&:hover': {
			// Overwrittes the different states of border
			borderColor: state.isFocused ? '#000' : null
		},
		'& > div': { paddingLeft: 0, paddingTop: 0 }
	}),
	container: base => ({
		...base,
		minHeight: 30,
		display: 'flex',
		position: 'unset'
	}),
	menu: (base, state) => ({
		...base,
		zIndex: 99,
		minWidth: 90,
		width: 'fit-content'
	}),
	clearIndicator: base => ({
		...base,
		display: 'none'
	}),
	placeholder: base => ({
		...base,
		paddingRight: 0,
		fontSize: '12px',
		color: '#000000'
	}),
	input: base => ({
		...base,
		'height': '100%',
		'margin': 0,
		'padding': 0,
		'& input': { height: '100%' }
	}),
	indicatorSeparator: () => {},
	dropdownIndicator: base => ({
		...base,
		'height': 20,
		'padding': 0,
		'& svg': { width: 18, height: 18 },
		'& svg path': { fill: '#000000', strokeWidth: 1 }
	}),
	option: (provided, state) => ({
		...provided,
		'backgroundColor': state.isSelected ? '#ABABAB' : '',
		'color': state.isSelected ? '#fff' : '#000',
		':hover': {
			backgroundColor: '#ABABAB',
			color: '#fff'
		}
	})
};

export const daysList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];

export const monthsList = [
	'ΙΑΝΟΥΑΡΙΟΣ',
	'ΦΕΒΡΟΥΑΡΙΟΣ',
	'ΜΑΡΤΙΟΣ',
	'ΑΠΡΙΛΙΟΣ',
	'ΜΑΪΟΣ',
	'ΙΟΥΝΙΟΣ',
	'ΙΟΥΛΙΟΣ',
	'ΑΥΓΟΥΣΤΟΣ',
	'ΣΕΠΤΕΜΒΡΙΟΣ',
	'ΟΚΤΩΒΡΙΟΣ',
	'ΝΟΕΜΒΡΙΟΣ',
	'ΔΕΚΕΜΒΡΙΟΣ'
];

export const yearsList = [
	2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013, 2012, 2011, 2010, 2009, 2008, 2007, 2006, 2005, 2004, 2003, 2002, 2001, 2000,
	1999, 1998, 1997, 1996, 1995, 1994, 1993, 1992, 1991, 1990, 1989, 1988, 1987, 1986, 1985, 1984, 1983, 1982, 1981, 1980, 1979, 1978, 1977,
	1976, 1975, 1974, 1973, 1972, 1971, 1970, 1969, 1968, 1967, 1966, 1965, 1964, 1963, 1962, 1961, 1960
];
