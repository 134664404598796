import TagManager from 'react-gtm-module';
import { isEmpty } from './helper.js';

const createCats = item => {
	const categories = {};

	item.breadcrumbs?.map((item, index) => {
		let key = 'item_category';
		if (index === 0) {
			key = `item_category`;
		} else {
			key = `item_category${index + 1}`;
		}
		return (categories[key] = item.nm);
	});

	return categories;
};

const calculatePrices = (product, dimensionId) => {
	let finalPrice = 0;
	let finalDiscount = 0;

	// Check if selected dimension has dimension.dsc field.
	// If yes, use dimension prices to perform calculations,
	// else use products' prices.
	const foundDimDsc = product.dimensions.filter(dim => dim.id === dimensionId).filter(dim => dim.prc !== undefined);

	if (foundDimDsc.length === 0) {
		// No sale on variation
		if (product.dsc === 0) {
			finalDiscount = 0;
		} else {
			finalDiscount = (product.prc / 100) * product.dsc;
		}
		finalPrice = product.prc - finalDiscount;
	} else {
		if (foundDimDsc[0].dsc === 0) {
			finalDiscount = 0;
		} else {
			finalDiscount = (foundDimDsc[0].prc / 100) * foundDimDsc[0].dsc;
		}
		finalPrice = foundDimDsc[0].prc - finalDiscount;
	}
	return { finalPrice, finalDiscount };
};

export const sendSelectItemGTMEvent = (product, idx, listname = null) => {
	if (!process.env.REACT_APP_G4) return;
	// if (!window.cookiesConsent.marketing) return;

	if (isEmpty(product)) return;
	if (product.dimensions?.length === 0) return;

	// Use the first dimensionId of the product as 'selected dimensionId'.
	const { finalPrice, finalDiscount } = calculatePrices(product, product.dimensions[0].id);
	const categories = createCats(product);

	window.dataLayer.push({ ecommerce: null });
	const tagManagerArgs = {
		dataLayer: {
			event: 'select_item',
			ecommerce: {
				item_list_name: listname,
				items: [
					{
						item_id: product.id + '-' + product.dimensions[0].id,
						item_name: product.nm,
						discount: Number(finalDiscount.toFixed(2)),
						index: idx,
						item_brand: product.mfnm,
						price: Number(finalPrice.toFixed(2))
					}
				]
			}
		}
	};

	let ecommerceItem = tagManagerArgs.dataLayer.ecommerce.items[0];

	// Push each category as separate line.
	for (const key in categories) {
		const value = categories[key];
		ecommerceItem[key] = value;
	}
	// console.log('select_item', tagManagerArgs.dataLayer.ecommerce);
	TagManager.dataLayer(tagManagerArgs);
};

export const sendViewItemGTMEvent = product => {
	if (!process.env.REACT_APP_G4) return;
	// if (!window.cookiesConsent.marketing) return;

	if (isEmpty(product)) return;
	if (product.dimensions?.length === 0) return;

	// Use the first dimensionId of the product as 'selected imensionId'.
	const { finalPrice, finalDiscount } = calculatePrices(product, product.dimensions[0].id);
	const categories = createCats(product);

	window.dataLayer.push({ ecommerce: null });
	const tagManagerArgs = {
		dataLayer: {
			event: 'view_item',
			ecommerce: {
				currency: 'EUR',
				items: [
					{
						item_id: product.id + '-' + product.dimensions[0].id,
						item_name: product.nm,
						discount: Number(finalDiscount.toFixed(2)),
						item_brand: product.mfnm,
						price: Number(finalPrice.toFixed(2))
					}
				]
			}
		}
	};

	let ecommerceItem = tagManagerArgs.dataLayer.ecommerce.items[0];

	// Push each category as separate line.
	for (const key in categories) {
		const value = categories[key];
		ecommerceItem[key] = value;
	}
	// console.log('view_item', tagManagerArgs.dataLayer.ecommerce);
	TagManager.dataLayer(tagManagerArgs);
};

export const sendViewItemListGTMEvent = (prods, startIndex, listname) => {
	if (!process.env.REACT_APP_G4) return;
	// if (!window.cookiesConsent.marketing) return;

	if (isEmpty(prods)) return;

	let index = startIndex;

	const products = prods
		.map(item => {
			if (item.dimensions?.length === 0) return null;

			// Use the first dimensionId of the product as 'selected imensionId'.
			const { finalPrice, finalDiscount } = calculatePrices(item, item.dimensions[0].id);
			const categories = createCats(item);

			let product = {
				item_id: item.id + '-' + item.dimensions[0].id,
				item_name: item.nm,
				discount: Number(finalDiscount.toFixed(2)),
				index: index,
				item_brand: item.mfnm,
				price: Number(finalPrice.toFixed(2)),
				item_list_name: listname
			};

			// Push each category as separate line.
			for (const key in categories) {
				const value = categories[key];
				product[key] = value;
			}

			index++;
			return product;
		})
		.filter(item => !!item);

	window.dataLayer.push({ ecommerce: null });
	const tagManagerArgs = {
		dataLayer: {
			event: 'view_item_list',
			ecommerce: {
				items: products
			}
		}
	};
	// console.log('view_item_list', tagManagerArgs.dataLayer.ecommerce);
	TagManager.dataLayer(tagManagerArgs);
};

export const sendBeginCheckoutGTMEvent = (orderTotal, orderItems) => {
	if (!process.env.REACT_APP_G4) return;
	// if (!window.cookiesConsent.marketing) return;

	if (isEmpty(orderItems)) return;

	const products = orderItems
		.map((item, index) => {
			if (item.dimensions?.length === 0) return null;

			const { finalPrice, finalDiscount } = calculatePrices(item.product, item.dimension);
			const categories = createCats(item.product);

			let product = {
				item_id: item.product.id + '-' + item.dimension,
				item_name: item.product.nm,
				item_brand: item.product.mfnm,
				index: index,
				price: Number(finalPrice.toFixed(2)),
				discount: Number(finalDiscount.toFixed(2)),
				quantity: item.quantity
			};

			// Push each category as separate line.
			for (const key in categories) {
				const value = categories[key];
				product[key] = value;
			}

			return product;
		})
		.filter(item => !!item);

	window.dataLayer.push({ ecommerce: null });
	const tagManagerArgs = {
		dataLayer: {
			event: 'begin_checkout',
			ecommerce: {
				currency: 'EUR',
				value: orderTotal,
				items: products
			}
		}
	};
	// console.log('begin_checkout', tagManagerArgs.dataLayer.ecommerce);
	TagManager.dataLayer(tagManagerArgs);
};

export const sendShippingInfoGTMEvent = (orderTotal, orderItems, courierLabel) => {
	if (!process.env.REACT_APP_G4) return;
	// if (!window.cookiesConsent.marketing) return;

	if (isEmpty(orderItems)) return;

	const products = orderItems
		.map(item => {
			if (item.dimensions?.length === 0) return null;

			const { finalPrice, finalDiscount } = calculatePrices(item.product, item.dimension);
			const categories = createCats(item.product);

			let product = {
				item_id: item.product.id + '-' + item.dimension,
				item_name: item.product.nm,
				item_brand: item.product.mfnm,
				discount: Number(finalDiscount.toFixed(2)),
				price: Number(finalPrice.toFixed(2)),
				quantity: item.quantity
			};

			// Push each category as separate line.
			for (const key in categories) {
				const value = categories[key];
				product[key] = value;
			}

			return product;
		})
		.filter(item => !!item);

	window.dataLayer.push({ ecommerce: null });
	const tagManagerArgs = {
		dataLayer: {
			event: 'add_shipping_info',
			ecommerce: {
				currency: 'EUR',
				value: orderTotal,
				shipping_tier: courierLabel,
				items: products
			}
		}
	};
	// console.log('add_shipping_info', tagManagerArgs.dataLayer.ecommerce);
	TagManager.dataLayer(tagManagerArgs);
};

export const sendPaymentInfoGTMEvent = (orderTotal, orderItems, paymentLabel) => {
	if (!process.env.REACT_APP_G4) return;
	// if (!window.cookiesConsent.marketing) return;

	if (isEmpty(orderItems)) return;

	const products = orderItems
		.map(item => {
			if (item.dimensions?.length === 0) return null;

			const { finalPrice, finalDiscount } = calculatePrices(item.product, item.dimension);
			const categories = createCats(item.product);

			let product = {
				item_id: item.product.id + '-' + item.dimension,
				item_name: item.product.nm,
				item_brand: item.product.mfnm,
				discount: Number(finalDiscount.toFixed(2)),
				price: Number(finalPrice.toFixed(2)),
				quantity: item.quantity
			};

			// Push each category as separate line.
			for (const key in categories) {
				const value = categories[key];
				product[key] = value;
			}

			return product;
		})
		.filter(item => !!item);

	window.dataLayer.push({ ecommerce: null });
	const tagManagerArgs = {
		dataLayer: {
			event: 'add_payment_info',
			ecommerce: {
				currency: 'EUR',
				value: orderTotal,
				payment_type: paymentLabel,
				items: products
			}
		}
	};
	// console.log('add_payment_info', tagManagerArgs.dataLayer.ecommerce);
	TagManager.dataLayer(tagManagerArgs);
};

export const sendPurchaseGTMEvent = (order, orderItems) => {
	if (!process.env.REACT_APP_G4) return;
	// if (!window.cookiesConsent.marketing) return;

	if (isEmpty(order)) return;

	const products = orderItems
		.map(item => {
			if (item.pdid === null) return null;

			let disc;
			if (item.luamt > 0) disc = item.uprbd - item.luamt;

			const categories = createCats(item);

			let product = {
				item_id: item.pid + '-' + item.pdid,
				item_name: item.pnm,
				discount: Number(disc.toFixed(2)),
				item_brand: item.pmfnm,
				price: item.luamt,
				quantity: item.qty
			};

			// Push each category as separate line.
			for (const key in categories) {
				const value = categories[key];
				product[key] = value;
			}

			return product;
		})
		.filter(item => !!item);

	window.dataLayer.push({ ecommerce: null });
	const tagManagerArgs = {
		dataLayer: {
			event: 'purchase',
			ecommerce: {
				transaction_id: order.uid,
				value: order.gamt,
				tax: order.tamt,
				shipping: order.spf,
				currency: 'EUR',
				coupon: order.coupon,
				items: products
			}
		}
	};
	// console.log('purchase', tagManagerArgs.dataLayer.ecommerce);
	TagManager.dataLayer(tagManagerArgs);
};

export const sendAddToCartGTMEvent = (product, dimensionId, qty) => {
	if (!process.env.REACT_APP_G4) return;
	// if (!window.cookiesConsent.marketing) return;

	if (isEmpty(product)) return;
	if (dimensionId === null || dimensionId === undefined) return;

	const { finalPrice, finalDiscount } = calculatePrices(product, dimensionId);
	const categories = createCats(product);

	window.dataLayer.push({ ecommerce: null });
	const tagManagerArgs = {
		dataLayer: {
			event: 'add_to_cart',
			ecommerce: {
				currency: 'EUR',
				value: qty * Number(finalPrice.toFixed(2)),
				items: [
					{
						item_id: product.id + '-' + dimensionId,
						item_name: product.nm,
						discount: Number(finalDiscount.toFixed(2)),
						item_brand: product.mfnm,
						price: Number(finalPrice.toFixed(2)),
						quantity: qty
					}
				]
			}
		}
	};

	let ecommerceItem = tagManagerArgs.dataLayer.ecommerce.items[0];

	// Push each category as separate line.
	for (const key in categories) {
		const value = categories[key];
		ecommerceItem[key] = value;
	}
	// console.log('add_to_cart', tagManagerArgs.dataLayer.ecommerce);
	TagManager.dataLayer(tagManagerArgs);
};

export const sendRemoveFromCartGTMEvent = (product, dimensionId, qty) => {
	if (!process.env.REACT_APP_G4) return;
	// if (!window.cookiesConsent.marketing) return;

	if (isEmpty(product)) return;
	if (dimensionId === null || dimensionId === undefined) return;

	const { finalPrice, finalDiscount } = calculatePrices(product, dimensionId);
	const categories = createCats(product);

	window.dataLayer.push({ ecommerce: null });
	const tagManagerArgs = {
		dataLayer: {
			event: 'remove_from_cart',
			ecommerce: {
				currency: 'EUR',
				value: qty * Number(finalPrice.toFixed(2)),
				items: [
					{
						item_id: product.id + '-' + dimensionId,
						item_name: product.nm,
						discount: Number(finalDiscount.toFixed(2)),
						item_brand: product.mfnm,
						price: Number(finalPrice.toFixed(2)),
						quantity: qty
					}
				]
			}
		}
	};

	let ecommerceItem = tagManagerArgs.dataLayer.ecommerce.items[0];

	// Push each category as separate line.
	for (const key in categories) {
		const value = categories[key];
		ecommerceItem[key] = value;
	}
	// console.log('remove', tagManagerArgs.dataLayer.ecommerce);
	TagManager.dataLayer(tagManagerArgs);
};

export const sendViewCartGTMEvent = (cartItems, cartTotals) => {
	if (!process.env.REACT_APP_G4) return;
	// if (!window.cookiesConsent.marketing) return;

	if (isEmpty(cartItems)) return;

	const products = cartItems
		.map(item => {
			if (item.dimensions?.length === 0) return null;

			const { finalPrice, finalDiscount } = calculatePrices(item.product, item.dimension);
			const categories = createCats(item.product);

			let product = {
				item_id: item.product.id + '-' + item.dimension,
				item_name: item.product.nm,
				discount: Number(finalDiscount.toFixed(2)),
				item_brand: item.product.mfnm,
				price: Number(finalPrice.toFixed(2)),
				quantity: item.quantity
			};

			// Push each category as separate line.
			for (const key in categories) {
				const value = categories[key];
				product[key] = value;
			}

			return product;
		})
		.filter(item => !!item);

	window.dataLayer.push({ ecommerce: null });
	const tagManagerArgs = {
		dataLayer: {
			event: 'view_cart',
			ecommerce: {
				currency: 'EUR',
				value: cartTotals,
				items: products
			}
		}
	};
	// console.log('view_cart', tagManagerArgs.dataLayer.ecommerce);
	TagManager.dataLayer(tagManagerArgs);
};

// user data
export const sendUserInfoGTMEvent = user => {
	if (!process.env.REACT_APP_G4) return;

	// if (!window.cookiesConsent.marketing) return;

	if (isEmpty(user)) return;

	const tagManagerArgs = {
		dataLayer: {
			user_data: {
				userId: user.id,
				email: user.eml,
				phone_number: user.address?.mob ?? '',
				address: {
					first_name: user.address?.fn ?? '',
					last_name: user.address?.ln ?? '',
					street: user.address?.adl ?? '',
					city: user.address?.ct ?? '',
					region: '',
					postal_code: user.address?.pc ?? ''
				}
			}
		}
	};

	TagManager.dataLayer(tagManagerArgs);
};
