export const convertUTCDateToLocalDate = date => {
	let newDate = new Date(date);
	newDate.setMinutes(date.getMinutes() - date.getTimezoneOffset());
	return newDate;
};

export const userIsBot = /bot|googlebot|prerender|crawler|spider|robot|crawling/i.test(navigator.userAgent);

export const dateFormatShort = {
	dateStyle: 'short',
	timeStyle: 'short',
	timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
};
export const dateFormatLong = {
	dateStyle: 'medium',
	timeStyle: 'medium',
	timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
};

export function formatDateString(d) {
	if (d == null) return '';

	// Add UTC Offset, else it parses is as local datetime
	d = d.slice(0, 19) + '.000Z';

	return new Intl.DateTimeFormat('el-GR', dateFormatLong).format(new Date(d));
}

export const getGenderLabel = genderId => {
	let gender = '';

	if (genderId == 1) {
		gender = 'male';
	} else if (genderId == 2) {
		gender = 'female';
	} else if (genderId == 3) {
		gender = 'other';
	} else if (genderId == 4) {
		gender = 'noDisclose';
	}

	return gender;
};

export const isEmpty = obj => {
	return Object.keys(obj).length === 0;
};

export const emptyProfile = () => {
	return {
		eml: '',
		mob: '',
		dob: '',
		gd: '',
		fn: '',
		ln: '',
		id: ''
	};
};

export const sortLabels = arr => {
	return arr.sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
};

export function formatAmountValue(value) {
	if (value != null) {
		return value.toFixed(2).toString() + '€';
	}

	return '';
}

export function formatRoundValue(value) {
	if (value != null) {
		return value.toFixed(0).toString();
	}

	return null;
}
export function formatPercentValue(value) {
	if (value != null) {
		return value.toString() + '%';
	}

	return null;
}

export function calculateProductPrice(product, dimensionId) {
	const priceValue = calculateProductPriceValue(product, dimensionId);
	return formatAmountValue(priceValue);
}

export function calculateProductPriceValue(product, dimensionId) {
	let price = product.prc;

	if (dimensionId) {
		const dimension = product.dimensions.find(x => x.id == dimensionId);

		if (dimension?.prc) {
			if (dimension.prcbd > 0) {
				price = dimension.prc;
			} else if (dimension.dsc > 0 && dimension.dsc < 100) {
				price = dimension.prc - (dimension.prc * dimension.dsc) / 100;
			}

			return roundToTwo(price);
		}
	}

	if (product.prcbd > 0) {
		price = product.prc;
	} else if (product.dsc > 0 && product.dsc < 100) {
		price = product.prc - (product.prc * product.dsc) / 100;
	}

	return roundToTwo(price);
}

export function calculateProductPriceBDValue(product, dimensionId) {
	let priceBD = 0;

	if (dimensionId) {
		const dimension = product.dimensions.find(x => x.id == dimensionId);

		if (dimension?.prc) {
			if (dimension.prcbd > 0) {
				priceBD = dimension.prcbd;
			} else if (dimension.dsc > 0 && dimension.dsc < 100) {
				priceBD = dimension.prc;
			}

			return roundToTwo(priceBD);
		}
	}

	if (product.prcbd > 0) {
		priceBD = product.prcbd;
	} else if (product.dsc > 0 && product.dsc < 100) {
		priceBD = product.prc;
	}

	return roundToTwo(priceBD);
}

function roundToTwo(num) {
	return +(Math.round(num + 'e+2') + 'e-2');
}
export function calculateProductPriceBD(product, dimensionId) {
	////New Greek Law so we hide it
	//return null;
	const priceValue = roundToTwo(calculateProductPriceValue(product, dimensionId));
	const referencePrice = roundToTwo(calculateProductReferencePriceValue(product, dimensionId));
	const priceBD = roundToTwo(calculateProductPriceBDValue(product, dimensionId));

	if (referencePrice > priceValue) {
		if (referencePrice > priceBD) {
			return formatAmountValue(priceBD) + ' ' + formatAmountValue(referencePrice);
		} else {
			return formatAmountValue(priceBD);
		}
	}

	return null;
}

export function calculateProductReferencePriceValue(product, dimensionId) {
	let referencePrice = product.rprc ?? 0;

	if (dimensionId) {
		const dimension = product.dimensions.find(x => x.id == dimensionId);

		if (dimension.rprc) {
			referencePrice = dimension.rprc;
		}
	}

	return roundToTwo(referencePrice);
}

export function calculateProductDiscount(product, dimensionId) {
	let discountCalculation = 0;

	if (dimensionId) {
		const dimension = product.dimensions.find(x => x.id === dimensionId);

		if (dimension?.id && dimension?.prc) {
			if (dimension.prcbd >= 0) {
				discountCalculation = ((dimension.prcbd - dimension.prc) / dimension.prcbd) * 100;
			} else if (dimension.dsc > 0 && dimension.dsc < 100) {
				discountCalculation = dimension.dsc;
			}
		} else {
			if (product.prcbd >= 0) {
				discountCalculation = ((product.prcbd - product.prc) / product.prcbd) * 100;
			} else if (product.dsc > 0) {
				discountCalculation = product.dsc;
			}
		}
	}

	return formatRoundValue(discountCalculation);
}
//export const setPassVisibillityClickListener = () => {
//    $('#togglePassVisibillity').click(function () {
//        let passInput = $(this).parent().find('input');
//        let showIcon = $(this).find('#showPassIcon');
//        let hideIcon = $(this).find('#hidePassIcon');

//        if ($(passInput).prop("type") == 'text') {
//            $(passInput).prop("type", "password");
//            $(showIcon).show();
//            $(hideIcon).hide();
//        } else {
//            $(passInput).prop("type", "text");
//            $(hideIcon).show();
//            $(showIcon).hide();
//        }
//    });
//};

export function normalize(text) {
	return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function parseJwt(token) {
	var base64Url = token.split('.')[1];
	var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	var jsonPayload = decodeURIComponent(
		window
			.atob(base64)
			.split('')
			.map(function (c) {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
			})
			.join('')
	);

	return JSON.parse(jsonPayload);
}

export const initFacebookSdk = () => {
	return new Promise(resolve => {
		// wait for facebook sdk to initialize before starting the react app
		window.fbAsyncInit = function () {
			window.FB.init({
				appId: process.env.REACT_APP_FACEBOOK_APP_ID,
				cookie: false,
				xfbml: true,
				version: 'v9.0'
			});

			resolve();
		};

		// load facebook sdk script
		(function (d, s, id) {
			var js,
				fjs = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) {
				return;
			}
			js = d.createElement(s);
			js.id = id;
			js.src = 'https://connect.facebook.net/en_US/sdk.js';
			fjs.parentNode.insertBefore(js, fjs);
		})(document, 'script', 'facebook-jssdk');
	});
};
