import { api_getProductDimensions } from '~/backend/api_calls';
import ShopCart from '~/backend/shop_cart';

export const validateProductsQuantities = async cartItems => {
	cartItems.forEach(async cartItem => {
		let maxQuantity = 0;

		if (cartItem.dimension == 0) {
			maxQuantity = cartItem.qty;
		} else {
			const response = await api_getProductDimensions(cartItem.product.id);

			for (let i = 0; i < response.items.length; i++) {
				let item = response.items[i];

				if (item.id == cartItem.dimension) {
					maxQuantity = item.qty !== undefined ? item.qty : cartItem.product.qty;
					break;
				}
			}
		}

		if (cartItem.quantity > maxQuantity) {
			throw {
				message: 'Insufficient quantity'
			};
		}
	});
};

export const setOrderlines = () => {
	var items = ShopCart.getItems();
	const lines = [];

	for (let i = 0; i < items.length; i++) {
		let orderLine = {
			pid: items[i].pid,
			pdid: items[i].dimension,
			qty: items[i].quantity
		};

		lines.push(orderLine);
	}

	return lines;
};
