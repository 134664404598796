import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { initFacebookSdk } from '~/common/helper';
import { notifyError } from '~/components/common/ToastMessages';
import facebookSVG from '~/images/fb.svg';
import { facebookLoginAction } from '~/store/actions.js';

const FacebookLoginButton = () => {
	const { t } = useTranslation();
	const [tokenResponse, setTokenResponse] = useState(null);
	const [credentials, setCredentials] = useState(null);
	const loginSuccessMessage = useSelector(state => state.authReducer.message);
	const dispatch = useDispatch();
	initFacebookSdk();

	const loginOrRegister = () => {
		FB.login(
			function (response) {
				setTokenResponse(response);
			},
			{ scope: 'public_profile,email' }
		);
	};

	//Use TokenResponse to get user's credentials from the Facebook GraphQL API
	useEffect(() => {
		if (tokenResponse === null) return;

		if (tokenResponse.status === 'not_authorized') {
			notifyError('Not Authorised');
			console.error('Not authorised');
			return;
		}

		if (tokenResponse.status === 'connected') {
			console.log(tokenResponse);
			fetch(
				'https://graph.facebook.com/' +
					tokenResponse.authResponse.userID +
					'?fields=id,first_name,last_name, email&access_token=' +
					tokenResponse.authResponse.accessToken
			)
				.then(response => response.json())
				.then(data => setCredentials(data));
			return;
		}
	}, [tokenResponse]);

	//Use user's credentials to actually login
	useEffect(() => {
		if (credentials === null) return;

		let args = {
			eml: credentials.email,
			fn: credentials.first_name,
			ln: credentials.last_name,
			facebook_id: credentials.id,
			psw: 'notNeeded'
		};

		dispatch(facebookLoginAction(args));
	}, [credentials]);

	return (
		<button className="flex-col" onClick={() => loginOrRegister()}>
			<img src={facebookSVG} />
			Facebook
		</button>
	);
};

export default FacebookLoginButton;
