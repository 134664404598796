import ReactPixel from 'react-facebook-pixel';
import { calculateProductPrice, calculateProductPriceValue, convertUTCDateToLocalDate, getGenderLabel, isEmpty } from './helper.js';

export const sendAddToCartFacebookEvent = product => {
	// if (!window.cookiesConsent.marketing) return;

	if (isEmpty(product)) return;

	ReactPixel.track('AddToCart', {
		content_type: 'product',
		content_ids: product.id,
		content_name: product.nm,
		currency: 'EUR',
		value: calculateProductPriceValue(product)
	});
};

export const sendPurchaseFacebookEvent = (order, orderItems) => {
	// if (!window.cookiesConsent.marketing) return;

	if (isEmpty(order)) return;

	const products = [];
	console.log(orderItems);

	orderItems.map(item => {
		let product = {
			id: item.pid,
			quantity: item.qty
		};

		products.push(product);
	});

	ReactPixel.track('Purchase', {
		content_type: 'product',
		value: order.gamt,
		currency: 'EUR',
		contents: products
	});
};

export const sendSearchFacebookEvent = (searchTerm, searchProducts) => {
	// if (!window.cookiesConsent.marketing) return;

	if (!searchTerm) return;

	const products = [];

	searchProducts.map(item => {
		let product = {
			id: item.id,
			quantity: item.qty
		};

		products.push(product);
	});

	const productIds = searchProducts.map(x => x.id);

	ReactPixel.track('Search', {
		content_type: 'product',
		content_category: 'Search',
		content_ids: productIds,
		contents: products,
		search_string: searchTerm
	});
};

export const sendViewContentFacebookEvent = product => {
	// if (!window.cookiesConsent.marketing) return;

	if (!product) return;

	ReactPixel.track('ViewContent', {
		content_type: 'product',
		content_category: product.cnm?.split(',')[0],
		content_ids: product.id,
		content_name: product.nm,
		currency: 'EUR',
		value: calculateProductPriceValue(product)
	});
};

export const sendInitiateCheckoutFacebookEvent = orderItems => {
	// if (!window.cookiesConsent.marketing) return;

	if (!orderItems) return;

	const products = [];
	console.log(orderItems);
	orderItems.map(item => {
		let product = {
			id: item.product.id,
			quantity: item.quantity
		};

		products.push(product);
	});

	ReactPixel.track('InitiateCheckout', {
		content_type: 'product',
		value: calculateCartTotals(orderItems),
		currency: 'EUR',
		contents: products
	});
};

export const sendCompleteRegistrationFacebookEvent = () => {
	// if (!window.cookiesConsent.marketing) return;

	ReactPixel.track('CompleteRegistration', {
		content_type: 'customer',
		status: 'true'
	});
};

export const sendContactFacebookEvent = () => {
	// if (!window.cookiesConsent.marketing) return;

	ReactPixel.track('Contact');
};

export const sendAddPaymentInfoFacebookEvent = paymentMethod => {
	// if (!window.cookiesConsent.marketing) return;

	ReactPixel.track('AddPaymentInfo', {
		paymentMethod: paymentMethod
	});
};

export const calculateCartTotals = orderItems => {
	let total = 0;
	orderItems.forEach(item => {
		total += calculateProductPriceValue(item.product) * item.quantity;
	});
	return total;
};
