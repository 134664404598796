import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import UserState from '~/backend/user_session';
import Button from '~/components/common/Button';

const SwitchAccount = ({ setForceShowLogin }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	return (
		<div className="content-wrapper">
			<div className="already-loggedin-container">
				<h2>
					{t('logged_in_as')} {!UserState.isAuthenticated() ? t('guest') : t('user')} {t('using_mail')}
				</h2>
				<input type="text" value={UserState.getEmail()} readOnly />
				<div className="actions">
					<Button color="secondary" actionOnClick={() => setForceShowLogin(true)}>
						{t('switch_account')}
					</Button>
					<Button color="secondary" outline={true} actionOnClick={() => navigate('/checkout-step-2')}>
						{t('checkout_next_step')}
					</Button>
				</div>
			</div>
		</div>
	);
};

SwitchAccount.propTypes = {
	setForceShowLogin: PropTypes.func
};

export default SwitchAccount;
