import { combineReducers } from 'redux';

// Combine all reducers in here

// Auth
import authReducer from '~/store/auth/reducer';

// Cookies
import cookiesReducer from '~/store/cookies/reducer';

// Checkout
import checkoutReducer from '~/core/checkout/store/reducer';

// Register
import registerReducer from '~/store/register/reducer';

// History
import historyReducer from '~/store/history/reducer';

// Filters
import filtersReducer from '~/store/filters/reducer';

// Coupons
import couponsReducer from '~/store/coupons/reducer';

// Cart
import cartReducer from '~/store/cart/reducer';

// Layout
import layoutReducer from '~/store/layout/reducer';

const rootReducer = combineReducers({
	authReducer,
	cookiesReducer,
	checkoutReducer,
	registerReducer,
	historyReducer,
	filtersReducer,
	couponsReducer,
	cartReducer,
	layoutReducer
});

export default rootReducer;
