export const supportedShippingCountriesIds = []; // Currently not used

export const countriesIdsThatSupportInvoice = [
	1 // Greece
];

export const countriesIdsThatHaveSeededPostalCodes = [
	1 // Greece
];

export const countriesIdsThatHaveSeededTaxOffices = [
	1 // Greece
];

export const handleSetInitialState = order => {
	const billingInformationInitialState = {
		adid: null,
		adtl: '',
		fnm: '',
		lnm: '',
		mob: '',
		ctid: 1, // Default Select Greece
		crid: null,
		adr1: '',
		ct: '',
		pc: '',
		rt: '',
		cnm: '',
		toid: null,
		irs: '',
		irscd: '',
		job: '',
		vat: ''
	};

	const shippingInformationInitialState = {
		dfnm: '',
		dlnm: '',
		dmob: '',
		dctid: 1, // Default Select Greece
		dcrid: null,
		dadr1: '',
		dct: '',
		dpc: '',
		ncm: ''
	};

	for (const property in billingInformationInitialState) {
		billingInformationInitialState[property] = order[property] || billingInformationInitialState[property];
	}

	for (const property in shippingInformationInitialState) {
		shippingInformationInitialState[property] = order[property] || shippingInformationInitialState[property];
	}

	return { billingInformationInitialState, shippingInformationInitialState };
};

export const validateCheckoutStep2Data = (billingInformation, shippingInformation, receiptType, shipToBillingAddress) => {
	let errorProperties = {};

	// if (receiptType === 0 && (!billingInformation.fnm || billingInformation.fnm.trim() === '')) {
	// 	errorProperties.fnm = 'required_field';
	// }

	// if (receiptType === 0 && (!billingInformation.lnm || billingInformation.lnm.trim() === '')) {
	// 	errorProperties.lnm = 'required_field';
	// }

	if (!billingInformation.fnm || billingInformation.fnm.trim() === '') {
		errorProperties.fnm = 'required_field';
	}

	if (!billingInformation.lnm || billingInformation.lnm.trim() === '') {
		errorProperties.lnm = 'required_field';
	}

	if (!billingInformation.mob || billingInformation.mob.trim() == '') {
		errorProperties.mob = 'required_field';
	}

	if (!billingInformation.mob || billingInformation.mob.trim() === '') {
		errorProperties.mob = 'required_field';
	} else {
		if (billingInformation.mob.startsWith('+30')) {
			const phoneWithoutPrefix = billingInformation.mob.replace('+30', '').trim();
			if (phoneWithoutPrefix.length !== 10 || isNaN(phoneWithoutPrefix)) {
				errorProperties.mob = 'invalid_mobile';
			}
		} else {
			if (billingInformation.mob.trim().length < 8 || billingInformation.mob.trim().length > 16) {
				errorProperties.mob = 'invalid_mobile';
			}
		}
	}

	if (!billingInformation.ctid) {
		errorProperties.ctid = 'required_field';
	}

	if (billingInformation.ctid == 1 && !billingInformation.crid) {
		errorProperties.crid = 'required_field';
	}

	if (!billingInformation.adr1 || billingInformation.adr1.trim() == '') {
		errorProperties.adr1 = 'required_field';
	}

	if (!billingInformation.ct || billingInformation.ct.trim() == '') {
		errorProperties.ct = 'required_field';
	}

	if (!billingInformation.pc || billingInformation.pc.trim() == '') {
		errorProperties.pc = 'required_field';
	}

	// If invoice selected
	if (receiptType === 1) {
		if (!billingInformation.cnm || billingInformation.cnm.trim() == '') {
			errorProperties.cnm = 'required_field';
		}

		if (!billingInformation.irs || billingInformation.irs.trim() == '') {
			errorProperties.irs = 'required_field';
		}

		if (!billingInformation.job || billingInformation.job.trim() == '') {
			errorProperties.job = 'required_field';
		}

		if (!billingInformation.vat || !validateGreekVatNumber(billingInformation.vat.trim())) {
			errorProperties.vat = 'Μη εγκυρος ΑΦΜ';
		}
	} else {
		billingInformation.cnm = null;
		billingInformation.irs = null;
		billingInformation.toid = null;
		billingInformation.job = null;
		billingInformation.vat = null;
	}

	if (!shipToBillingAddress) {
		if (!shippingInformation.dfnm || shippingInformation.dfnm.trim() == '') {
			errorProperties.dfnm = 'required_field';
		}

		if (!shippingInformation.dlnm || shippingInformation.dlnm.trim() == '') {
			errorProperties.dlnm = 'required_field';
		}

		if (!shippingInformation.dmob || shippingInformation.dmob.trim() == '') {
			errorProperties.dmob = 'required_field';
		}

		if (!billingInformation.mob || billingInformation.mob.trim() === '') {
			errorProperties.mob = 'required_field';
		} else {
			if (billingInformation.mob.startsWith('+30')) {
				const phoneWithoutPrefix = billingInformation.mob.replace('+30', '').trim();
				if (phoneWithoutPrefix.length !== 10 || isNaN(phoneWithoutPrefix)) {
					errorProperties.mob = 'invalid_mobile';
				}
			} else {
				if (billingInformation.mob.trim().length < 8 || billingInformation.mob.trim().length > 16) {
					errorProperties.mob = 'invalid_mobile';
				}
			}
		}

		if (!shippingInformation.dctid) {
			errorProperties.dctid = 'required_field';
		}

		// if (shippingInformation.dctid != 1 && shippingInformation.dctid != 58) {
		// 	errorProperties.dctid = 'country_not_supported';
		// }

		if (shippingInformation.dctid == 1 && !shippingInformation.dcrid) {
			errorProperties.dcrid = 'required_field';
		}

		if (!shippingInformation.dadr1 || shippingInformation.dadr1.trim() == '') {
			errorProperties.dadr1 = 'required_field';
		}

		if (!shippingInformation.dct || shippingInformation.dct.trim() == '') {
			errorProperties.dct = 'required_field';
		}

		if (!shippingInformation.dpc || shippingInformation.dpc.trim() == '') {
			errorProperties.dpc = 'required_field';
		}
	} else {
		shippingInformation.dfnm = billingInformation.fnm;
		shippingInformation.dlnm = billingInformation.lnm;
		shippingInformation.dmob = billingInformation.mob;
		shippingInformation.dctid = billingInformation.ctid;
		shippingInformation.dcrid = billingInformation.crid;
		shippingInformation.dadr1 = billingInformation.adr1;
		shippingInformation.dct = billingInformation.ct;
		shippingInformation.dpc = billingInformation.pc;
	}

	// Validate shipping country | Only greece & cyprus supported
	// if (shippingInformation.dctid !== 1 && shippingInformation.dctid !== 58) {
	// 	errorProperties.dctid = 'country_not_supported';
	// }

	if (billingInformation.ctid !== 1) {
		billingInformation.toid = null;
	}

	if (Object.keys(errorProperties).length === 0) {
		return {
			...billingInformation,
			...shippingInformation,
			rt: receiptType,
			tt: 0
		};
	} else {
		throw {
			message: 'Error inputs at checkout step 2',
			payload: errorProperties
		};
	}
};

const validateGreekVatNumber = vat_number => {
	var count_digits = vat_number.length;
	var array = vat_number.split('');

	var sum_of_digits = 0;
	for (let i = 0; i < 9; i++) {
		sum_of_digits = sum_of_digits + Number(vat_number[i]);
	}

	if (count_digits != 9 || sum_of_digits == 0) {
		return false;
	} else {
		//A1 calculation as the readme.md file describes
		var m = 256;
		var a1 = 0;

		for (let i = 0; i < 8; i++) {
			a1 = a1 + Number(array[i]) * m;
			m = m / 2;
		}

		//A2 calculation as the readme.md file describes
		var a2 = a1 % 11;

		//Check if c9 is equal with a2 modulo 10
		if (Number(array[8]) == a2 % 10) {
			return true;
		} else {
			return false;
		}
	}
};
