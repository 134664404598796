import CheckoutSessionStorage from '~/components/checkout/OrderSynopsis/utils/CheckoutSessionStorage';

const initialState = {
	coupons: CheckoutSessionStorage.getCoupons(),
	canRedeemPoints: false,
	redeemPoints: false
};

const checkoutReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'set-coupons':
			state = { ...state, coupons: action.coupons };
			break;
		case 'set-can-redeem-points':
			state = { ...state, canRedeemPoints: action.canRedeemPoints };
			break;
		case 'set-redeem-points':
			state = { ...state, redeemPoints: action.redeemPoints };
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};

export default checkoutReducer;
