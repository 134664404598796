import { isEmpty } from './helper.js';
import gtag from 'ga-gtag';

export const sendPurchaseGTAG4Event = (order, orderItems) => {
	if (!process.env.REACT_APP_GTAG) return;
	// if (!window.cookiesConsent.marketing) return;

	if (isEmpty(order)) return;

	const products = [];

	orderItems.map(item => {
		let product = {
			item_id: item.pdid === null ? item.pid : item.pid + '-' + item.pdid,
			item_name: item.pnm,
			item_brand: item.pmfnm,
			item_category: item.cnm,
			price: item.upr,
			discount: item.dsc,
			quantity: item.qty
		};

		products.push(product);
	});

	gtag('event', 'purchase', {
		transaction_id: order.uid,
		value: order.gamt,
		tax: order.tamt,
		shipping: order.spf,
		currency: 'EUR',
		coupon: order.coupon,
		items: products
	});
};

export const sendConversionGTAG4Event = order => {
	if (!process.env.REACT_APP_GTAG) return;
	// if (!window.cookiesConsent.marketing) return;

	if (isEmpty(order)) return;

	gtag('event', 'conversion', {
		send_to: 'AW-1036597768/SFjnCKLK2wYQiPSk7gM',
		transaction_id: order.uid,
		value: order.gamt,
		currency: 'EUR'
	});
};

export const sendAddToCartGTAG4Event = (product, dimensionId, qty) => {
	if (!process.env.REACT_APP_GTAG) return;
	// if (!window.cookiesConsent.marketing) return;

	if (isEmpty(product)) return;
	let disc;

	if (product.prcbd > 0) disc = product.prcbd - product.prc;

	gtag('event', 'add_to_cart', {
		currency: 'EUR',
		value: product.prc,
		items: [
			{
				item_id: dimensionId === null ? product.id : product.id + '-' + dimensionId,
				item_name: product.nm,
				item_brand: product.mfnm,
				item_category: product.cnm,
				price: product.prc,
				discount: disc,
				quantity: qty
			}
		]
	});
};

export const sendRemoveFromCartGTAG4Event = (product, dimensionId, qty) => {
	if (!process.env.REACT_APP_GTAG) return;
	// if (!window.cookiesConsent.marketing) return;

	if (isEmpty(product)) return;
	let disc;

	if (product.prcbd > 0) disc = product.prcbd - product.prc;

	gtag('event', 'remove_from_cart', {
		currency: 'EUR',
		value: product.prc,
		items: [
			{
				item_id: dimensionId === null ? product.id : product.id + '-' + dimensionId,
				item_name: product.nm,
				item_brand: product.mfnm,
				item_category: product.cnm,
				price: product.prc,
				discount: disc,
				quantity: qty
			}
		]
	});
};
