import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Transition } from 'react-transition-group';
import cartIcon from '~/images/cart-icon.svg';
import noimage_png from '~/images/no-image.png';
import searchIcon from '~/images/search-icon.svg';
import userIcon from '~/images/user-icon.svg';
import wishlistIcon from '~/images/wishlist-icon.svg';
import Login from '../../../pages/Customer/Login.js';
import UseOnScreen from '../../hooks/UseOnScreen';

import UserState from '~/backend/user_session';
import logo from '~/images/bdtk-logo.svg';
// import logo from '~/images/logo-support.png';

import {
	api_getContentPageByCode,
	api_getProductsByIds,
	api_getProfile,
	api_searchCategories,
	api_searchProducts
} from '~/backend/api_calls';
import SearchBar from '~/components/common/SearchBar';
import TopMessage from './TopMessage';

import { calculateProductPrice, calculateProductPriceBD, isEmpty } from '~/common/helper';

import UserSession from '~/backend/user_session';

import { useDispatch, useSelector } from 'react-redux';
import CartWidget from '~/components/common/CartWidget.js';
import { openCartWidgetAction, showLoginModal } from '~/store/actions.js';
import CheckoutStep1 from '../../../pages/CheckoutStep1.js';

import PersonalisedProducts from '~/backend/personalised_products.js';
import { sendSearchFacebookEvent } from '~/common/facebookPixel';
import { CategorySearchLoader, ProductSearchLoader } from '~/components/common/SkeletonLoaders';

import { sendUserInfoGTMEvent } from '~/common/gtm.js';

const SearchProductBox = props => {
	const showPrice = () => {
		return (
			<div>
				{calculateProductPriceBD(props.product) && <span>{calculateProductPriceBD(props.product)}</span>}
				{calculateProductPrice(props.product)}
			</div>
		);
	};

	return (
		<div className="product-box" id={props.product.id}>
			<div className="prod-img">
				<img
					src={props.product.img1 ? props.product.img1 : noimage_png}
					alt={props.product.nm}
					width="282"
					height="385"
					loading="lazy"
					style={{ width: '100%', height: 'auto' }}
				/>
			</div>

			<div className="prod-details">
				<div className="flex-col">
					<div>
						<h4>{props.product.nm}</h4>
					</div>

					<div className="prod-price">{showPrice()}</div>
				</div>
			</div>
		</div>
	);
};

SearchProductBox.propTypes = {
	product: PropTypes.object
};

const Header = () => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const headerCartQuantity = useSelector(state => state.cartReducer.headerCartQuantity);
	const showLogin = useSelector(state => state.authReducer.showLoginModal);
	const showChechoutLoginModal = useSelector(state => state.authReducer.showCheckoutLoginModal);
	const openCartWidget = useSelector(state => state.cartReducer.showCartWidget);
	const [SearchTransition, setSearchTransition] = useState(false);
	const [topMessage, setTopMessage] = useState();
	const headerRef = useRef();
	const isHeaderVisible = UseOnScreen(headerRef);
	const location = useLocation();
	const isAuthenticated = UserState.isAuthenticated();

	const nodeRef = useRef(null);

	useEffect(() => {
		setCloseModalBtnListener();
	}, []);

	useEffect(() => {
		if (isHeaderVisible) {
			const headerHeight = headerRef.current.clientHeight;
			window.document.getElementById('menu-modal').style.top = `${headerHeight}px`;
		}
	}, [isHeaderVisible, topMessage]);

	// gtm
	useEffect(() => {
		if (UserState.isAuthenticated()) {
			api_getProfile().then(json => {
				sendUserInfoGTMEvent(json);
			});
		} else if (UserState.getEmail()) {
			sendUserInfoGTMEvent({ eml: UserState.getEmail() });
		}
	}, [isAuthenticated]);

	const toggleLogin = () => {
		document.body.classList.add('overflow');
		dispatch(showLoginModal(true));
	};

	const closeMobileAccount = () => {
		window.document.querySelector('.login-register-mobile').classList.remove('active');
	};

	const setCloseModalBtnListener = () => {
		function handleClickOutside(event) {
			let modal = window.document.getElementById('menu-modal');
			let burger = window.document.getElementById('burger-btn');
			let header = window.document.getElementsByTagName('header')[0];

			if (modal && !modal.contains(event.target) && burger && !burger.contains(event.target)) {
				modal.style.opacity = '0';
				modal.style.pointerEvents = 'none';
				modal.classList.remove('show');
				header.classList.remove('line-header');
				window.document.getElementById('burger-btn').classList.remove('active');
				{
					/*visiblemenu.style.display = "inline-flex";*/
				}
			}
		}
		document.addEventListener('mousedown', handleClickOutside);
	};

	const toggleCartWidget = () => {
		const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

		if (vw <= 1000) {
			navigate('/cart');
			dispatch(openCartWidgetAction(false));
			return;
		}
		if (window.document.getElementById('burger-btn').classList.contains('active')) {
			window.document.getElementById('burger-btn').click();
		}

		if (openCartWidget) {
			document.body.classList.remove('cart-open');
			dispatch(openCartWidgetAction(false));
		} else {
			document.body.classList.add('cart-open');
			dispatch(openCartWidgetAction(true));
			setSearchTransition(false);
		}
	};

	MenuItem.propTypes = {
		title: PropTypes.string,
		link: PropTypes.string
	};

	function MenuItem(props) {
		const currentid = 'visible-' + props.title;

		const openModalCategory = () => {
			if (!window.document.getElementById('burger-btn').classList.contains('active')) {
				window.document.getElementById('burger-btn').click();
			}

			const trimmedId = currentid.replace('visible-', '');
			window.document.getElementById(trimmedId).click();
		};

		return (
			<div className="menu-item visible-menu-item" id={currentid} onClick={openModalCategory}>
				<a href={props.link}>{t(props.title)}</a>
			</div>
		);
	}

	function Search() {
		const [loading, setLoading] = useState(false);
		const [searchProducts, setSearchProducts] = useState({});
		const [searchCategories, setSearchCategories] = useState({});
		const [popularTerms, setPopularTerms] = useState(null);
		const [products, setProducts] = useState([]);
		const productsIds = PersonalisedProducts.getItems();

		const navigate = useNavigate();
		let searchParameters = {
			enrich: false,
			keyword: '',
			pageIndex: 0,
			pageSize: 3,
			categoryIds: '',
			status: 0
		};

		useEffect(() => {
			api_getContentPageByCode('search_popular_terms').then(json => setPopularTerms(json));

			api_getProductsByIds(productsIds.toString())
				.then(json => setProducts(json.items))
				.catch(error => console.error(error));
		}, []);

		const onSearchSubmit = term => {
			setLoading(true);
			window.document.getElementById('search-screen').classList.add('search-clicked');
			/* const quotesArray = await requestQuotes(term.toLowerCase());*/
			searchParameters.keyword = term;

			api_searchProducts(searchParameters)
				.then(json => {
					setLoading(false);
					setSearchProducts(json);
					sendSearchFacebookEvent(term, json.prds);
				})
				.catch(error => {
					setLoading(false);
					console.log(error);
				});

			api_searchCategories(term)
				.then(json => {
					setLoading(false);
					setSearchCategories(json);
				})
				.catch(error => {
					setLoading(false);
					console.log(error);
				});
		};

		const clearResults = () => {};

		return (
			<div id="search-screen" className="search-screen">
				<div className="content-wrapper">
					<div className="exit-search">
						<div
							id="search-close"
							onClick={() => {
								setSearchTransition(false);
							}}>
							<svg xmlns="http://www.w3.org/2000/svg" width="19.092" height="19.092" viewBox="0 0 19.092 19.092">
								<g id="Group_173780" data-name="Group 173780" transform="translate(-111.793 -55.793)">
									<g id="Group_174096" data-name="Group 174096" transform="translate(0 -7)">
										<line
											id="Line_3"
											data-name="Line 3"
											x2="25"
											transform="translate(112.5 81.178) rotate(-45)"
											fill="none"
											stroke="#000"
											strokeWidth="2"
										/>
										<line
											id="Line_513"
											data-name="Line 513"
											x2="25"
											transform="translate(112.5 63.5) rotate(45)"
											fill="none"
											stroke="#000"
											strokeWidth="2"
										/>
									</g>
								</g>
							</svg>
						</div>
					</div>
					<SearchBar onSearchSubmit={onSearchSubmit} clearResults={clearResults} />

					<div className="results-section before-search-section">
						<div className="keyword-results">
							<div className="keyword-title">{t('popular_searches')}</div>
							<span
								dangerouslySetInnerHTML={{
									__html: popularTerms?.bd
								}}></span>
						</div>

						<div className="suggested-products">
							<div className="keyword-title">{t('you_could_like')}</div>
							<div id="suggested-products">
								{products.slice(0, 2).map(product => (
									<a className="product-item" href={product.eurl} key={product.id}>
										<div className="image-container">
											<img className="product-image" src={product.img1 !== undefined ? product.img1 : noimage_png} />
										</div>
										<span className="product-title">{product.nm}</span>
										<span className="product-price">{calculateProductPrice(product)}</span>
									</a>
								))}
							</div>
						</div>
					</div>

					<div
						className="results-section after-search-section"
						onClick={() => {
							setSearchTransition(false);
						}}>
						<div className="keyword-results">
							<div className="keyword-title">{t('search_results')}</div>
							{loading ? (
								<CategorySearchLoader />
							) : isEmpty(searchCategories) || searchCategories.cc == 0 ? (
								<p className="no-results">{t('search_keywords_results_none')}</p>
							) : (
								searchCategories.cats.map(category => (
									<Link className="keyword-item" key={category.id} to={category.eurl ? category.eurl : ''}>
										{category.nm}
									</Link>
								))
							)}
						</div>

						<div className="products-results">
							{loading ? (
								<ProductSearchLoader />
							) : isEmpty(searchProducts) || searchProducts.pc == 0 ? (
								<p className="no-results">{t('search_products_results_none')}</p>
							) : (
								searchProducts.prds.map(product => (
									<Link className="product-item" key={product.id} to={product.eurl ? product.eurl : ''}>
										<SearchProductBox key={product.id} product={product} />
									</Link>
								))
							)}
						</div>

						{!isEmpty(searchProducts) && searchProducts.pc > 0 ? (
							<div className="go-to-search-page">
								<button type="button" onClick={() => navigate('/search?keyword=' + searchProducts.keyword)}>
									{t('search_browse_results')} ({searchProducts.pc})
								</button>
							</div>
						) : null}
					</div>
				</div>
			</div>
		);
	}

	const changeLanguage = () => {
		const oldLang = localStorage.getItem('I18N_LANGUAGE');
		const lang = localStorage.getItem('I18N_LANGUAGE') == 'el' ? 'en' : 'el';
		let { pathname } = location;

		i18n.changeLanguage(lang);
		localStorage.setItem('I18N_LANGUAGE', lang);

		// Update url
		var url = '';

		if (pathname == null || pathname == '') {
			pathname = '/';
		}
		if (pathname.length > 3 && !pathname.startsWith('/el/') && !pathname.startsWith('/en/')) {
			url = '/' + lang + pathname;
		} else {
			if (pathname.startsWith('/' + oldLang)) {
				url = pathname.replace('/' + oldLang, '/' + lang);
			} else {
				url = '/' + lang + pathname;
			}
		}

		window.location.href = url;
	};

	const burgerToggle = () => {
		var modal = window.document.getElementById('menu-modal');
		var header = window.document.getElementsByTagName('header')[0];
		var mobile_account = window.document.querySelector('.login-register-mobile');
		{
			/*var visiblemenu = document.querySelector('.visible-menu');*/
		}

		if (!modal.classList.contains('show')) {
			dispatch(openCartWidgetAction(false));
			setSearchTransition(false);

			modal.style.opacity = '1';
			modal.style.pointerEvents = 'auto';
			modal.classList.add('show');
			header.classList.add('line-header');
			{
				/*visiblemenu.style.display = "none";*/
			}
			mobile_account.classList.add('active');
		} else {
			modal.style.opacity = '0';
			modal.style.pointerEvents = 'none';
			modal.classList.remove('show');
			header.classList.remove('line-header');
			{
				/*visiblemenu.style.display = "inline-flex";*/
			}
			mobile_account.classList.remove('active');
		}

		window.document.getElementById('burger-btn').classList.toggle('active');
	};

	return (
		<>
			{showLogin && <Login />}
			{showChechoutLoginModal && <CheckoutStep1 />}
			<header ref={headerRef} id="default-header">
				<TopMessage topMessage={topMessage} setTopMessage={setTopMessage} />
				<div className="content-wrapper">
					<div id="burger-btn" onClick={burgerToggle}>
						<svg className="open-burger" xmlns="http://www.w3.org/2000/svg" width="32" height="8.666" viewBox="0 0 32 8.666">
							<g transform="translate(-109 -61.667)">
								<line x2="32" transform="translate(109 62.667)" fill="none" stroke="#000" strokeWidth="2" />
								<line x2="25" transform="translate(109 69.333)" fill="none" stroke="#000" strokeWidth="2" />
							</g>
						</svg>
						<svg className="close-burger" xmlns="http://www.w3.org/2000/svg" width="19.092" height="19.092" viewBox="0 0 19.092 19.092">
							<g transform="translate(-111.793 -55.793)">
								<g transform="translate(0 -7)">
									<line x2="25" transform="translate(112.5 81.178) rotate(-45)" fill="none" stroke="#000" strokeWidth="2" />
									<line x2="25" transform="translate(112.5 63.5) rotate(45)" fill="none" stroke="#000" strokeWidth="2" />
								</g>
							</g>
						</svg>
					</div>
					<div className="lang-change left-lang">
						<button
							type="button"
							id="lang-name"
							className={localStorage.getItem('I18N_LANGUAGE') == 'el' ? 'lang-gr' : 'lang-en'}
							onClick={() => changeLanguage()}>
							{localStorage.getItem('I18N_LANGUAGE') == 'el' ? 'EL' : 'EN'}
						</button>
					</div>

					<div id="logo">
						<Link to="/">
							<img src={logo} alt="BODYTALK" />
						</Link>
					</div>

					<div className="header-options">
						<div id="view-search">
							<button
								type="button"
								className="active"
								onClick={() => {
									setSearchTransition(setSearchTransition => !setSearchTransition);
								}}>
								<img src={searchIcon} alt="SEARCH" />
							</button>
						</div>
						<div
							id="user-login"
							onClick={() => {
								closeMobileAccount();
							}}>
							{UserSession.isAuthenticated() ? (
								<Link to="/profile" className="active">
									<img src={userIcon} alt="LOGIN" />
								</Link>
							) : (
								<button type="button" className="active" onClick={toggleLogin}>
									<img src={userIcon} alt="LOGIN" />
								</button>
							)}
						</div>
						<div
							id="view-wishlist"
							onClick={() => {
								closeMobileAccount();
							}}>
							{UserSession.isAuthenticated() ? (
								<Link to="/wishlists" className="active">
									<img src={wishlistIcon} alt="WISHLIST" />
								</Link>
							) : (
								<Link to="/wishlist" className="active">
									<img src={wishlistIcon} alt="WISHLIST" />
								</Link>
							)}
						</div>
						<div id="view-cart">
							<button
								className="active"
								onClick={() => {
									toggleCartWidget();
									closeMobileAccount();
								}}>
								<img src={cartIcon} alt="CART" />
								<span>{headerCartQuantity}</span>
							</button>
						</div>
						{/*<div id="lang-name" className="lang-change right-lang">{localStorage.getItem("I18N_LANGUAGE") == "el" ? "EL" : "EN"}</div>*/}
						{/*<div className="hover-lang">*/}
						{/*    <button type="button" className={localStorage.getItem("I18N_LANGUAGE") == "el" ? "lang-gr" : "lang-en"} onClick={() => changeLanguage()}>*/}
						{/*        {localStorage.getItem("I18N_LANGUAGE") == "el" ? "EN" : "EL"}*/}
						{/*    </button>*/}
						{/*</div>*/}
						<div className="lang-change right-lang">
							<button
								type="button"
								id="lang-name"
								className={localStorage.getItem('I18N_LANGUAGE') == 'el' ? 'lang-gr' : 'lang-en'}
								onClick={() => changeLanguage()}>
								{localStorage.getItem('I18N_LANGUAGE') == 'el' ? 'EL' : 'EN'}
							</button>
						</div>
					</div>
				</div>
			</header>

			{openCartWidget && <CartWidget />}

			<Transition nodeRef={nodeRef} in={SearchTransition} timeout={300} classNames="search-transition" unmountOnExit>
				<Search />
			</Transition>

			{/*Login - Register Section for mobile*/}
			<div className="login-register-mobile">
				{UserSession.isAuthenticated() ? (
					<React.Fragment>
						<button
							className="login-button"
							onClick={() => {
								closeMobileAccount();
								navigate('/profile');
							}}>
							{t('my_account')}
						</button>
						<button
							className="register-button"
							onClick={() => {
								closeMobileAccount();
								navigate('/orders');
							}}>
							{t('my_orders')}
						</button>
					</React.Fragment>
				) : (
					<React.Fragment>
						<button
							className="login-button"
							onClick={() => {
								toggleLogin();
								closeMobileAccount();
							}}>
							{t('sign_in')}
						</button>
						<button
							className="register-button"
							onClick={() => {
								toggleLogin();
								closeMobileAccount();
							}}>
							{t('register')}
						</button>
					</React.Fragment>
				)}
			</div>
		</>
	);
};

export default Header;
