import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { api_getShippingTypes } from '~/backend/api_calls';
import UserSession from '~/backend/user_session';
import Button from '~/components/common/Button';
import { notifyError } from '~/components/common/ToastMessages';
import CheckoutSessionStorage from '~/core/checkout/utils/CheckoutSessionStorage';
import { handleOrderCompletedResponse } from '~/core/checkout/utils/checkout_step_3';
import { orderCompleteAction, resetCheckoutStore, setInputsErrorsAction } from '~/store/actions';
import Addresses from './Components/Addresses';
import BillingInputs from './Components/BillingInputs';
import PaymentOptions from './Components/PaymentOptions';
import { handleValidateBillingInformation } from './utils/giftcard';

const GiftcardStep5 = ({ metaData, setMetaData }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const payment = useSelector(state => state.checkoutReducer.payment);
	const orderResponse = useSelector(state => state.checkoutReducer.orderResponse);
	const [disabledButton, setDisabledButton] = useState(false);

	useEffect(() => {
		if (orderResponse.uid == null || orderResponse.uid.length == 0) return;

		try {
			const { navUrl, replace } = handleOrderCompletedResponse(orderResponse);

			if (replace) {
				window.location.replace(navUrl);
			} else {
				navigate(navUrl);
			}
		} catch (e) {
			console.log(e.msg);
			navigate('/checkout-failure');
		}
	}, [orderResponse]);

	async function handleCompleteOrder() {
		try {
			setDisabledButton(true);
			dispatch(setInputsErrorsAction({})); // remove errors
			handleValidateBillingInformation(metaData, payment);

			const order = {
				gfnm: metaData.name,
				gffnm: metaData.friendName,
				gfmsg: metaData.message,
				gfremail: metaData.recipientEmail,
				gfsdate: metaData.sendDate,
				eml: metaData.billingEmail,
				fnm: metaData.billingName,
				lnm: metaData.billingLastname,
				mob: metaData.billingTelephone,
				ctid: metaData.billingCountryId,
				adr1: metaData.billingAddress,
				ct: metaData.billingCity,
				pc: metaData.billingPostalCode,
				rt: 0,
				tt: 0,
				ptid: payment.id,
				r_lpnt: 0,
				lines: [
					{
						pid: metaData.giftcard.id,
						pdid: metaData.giftcard.dimensions[0]?.id,
						qty: metaData.qty
					}
				]
			};

			// Set giftcard shipment type id
			const json = await api_getShippingTypes();
			const shippingOption = json.items.find(option => option.cd === 'GIFTCARD');
			order.stid = shippingOption?.id;

			UserSession.setEmail(metaData.billingEmail);
			CheckoutSessionStorage.setOrder({}); // Remove left order checkout information from default checkout
			dispatch(resetCheckoutStore()); // Remove left order checkout information from default checkout

			dispatch(orderCompleteAction(order));
		} catch (e) {
			dispatch(setInputsErrorsAction(e.payload));
			notifyError(t(e.message));
			setDisabledButton(false);
		}
	}

	return (
		<div className="giftcard-step">
			<div className="fifth-step">
				<h3> {t('checkout_billing_info_title')} </h3>

				{UserSession.isAuthenticated() && <Addresses metaData={metaData} setMetaData={setMetaData} />}

				<BillingInputs metaData={metaData} setMetaData={setMetaData} />

				<PaymentOptions />

				<div className="next-step-button">
					<Button disabled={disabledButton} color="secondary" actionOnClick={() => handleCompleteOrder()}>
						{t('payment')} {metaData.giftcard.prc * metaData.qty}€
					</Button>
				</div>
			</div>
		</div>
	);
};

GiftcardStep5.propTypes = {
	metaData: PropTypes.object,
	setMetaData: PropTypes.func
};

export default GiftcardStep5;
